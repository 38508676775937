enum MaterialType {
  Waste = "waste",
  Recycle = "recycle",
  Organic = "organic",
  Special = "special",
  CRC = "crc",
  CDS = "cds",
  DropOff = "waste_drop_off",
  CleanUp = "clean_up",
  NearMeMap = "near_me_map",
  RecyclingDropOff = "recycling_drop_off",
}

export class Material {
  public id: number;
  public title: string;
  public image: string;
  public description: string;
  public bin_type: string;
  public icon = "bin-circle";
  public category_id: number;
  public keywords: string;
  public readonly icons = [
    "waste",
    "recycle",
    "organic",
    "waste_drop_off",
    "clean_up",
    "near_me_map",
    "recycling_drop_off",
  ];

  constructor(
    id: number,
    title: string,
    image: string,
    description: string,
    bin_type: string,
    category_id?: number,
    keywords?: string
  ) {
    this.id = id;
    this.title = title;
    this.image = image || "./assets/images/no image available.png";
    this.description = description;
    this.bin_type = bin_type;
    this.category_id = category_id;
    this.keywords = keywords;
    this.setIcon();
  }

  private setIcon(): void {
    switch (this.bin_type) {
      case MaterialType.Special:
        this.icon = "";
        break;
      case MaterialType.DropOff:
        this.icon = "near-me";
        break;
      case MaterialType.CleanUp:
        this.icon = "kerbside-clean-up";
        break;
      case MaterialType.CRC:
        this.icon = "trailer-dropoff";
        break;
      case MaterialType.CDS:
        this.icon = "cds";
        break;
      case MaterialType.NearMeMap:
        this.icon = "near-me";
        break;
      case MaterialType.RecyclingDropOff:
        this.icon = "near-me";
        break;
    }
  }
}
