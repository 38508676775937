export const common = {
  council: "cowra",
  apiToken: "be5e6e957e4662808aa57e207cc9e5d3",
  oneSignalAppID: "8a11dc85-6a2e-4808-86c4-a6bd3f8a19fb",
  googleSenderID: "19875509330",
  googleApiKey: "AIzaSyDbgx-34VCjSwnqcb9GoAo4wJ970GYysKo",
  appVersion: "1.0.1.0",
  googleDirectionsUrl: "https://maps.googleapis.com/maps/api/geocode/json",
  vouchersInfoId: 45,
  defaultPageSize: 50,
  mapMarkerPath:
    "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
  firebaseConfig: {
    apiKey: "AIzaSyAT5z3vNzpK1Wy8sHnDBeq8R28PhXNIDQU",
    authDomain: "cowra-waste-info.firebaseapp.com",
    projectId: "cowra-waste-info",
    storageBucket: "cowra-waste-info.appspot.com",
    messagingSenderId: "479664620932",
    appId: "1:479664620932:web:eb46104ec0166b7be2c9d1",
    measurementId: "G-JCHCSKJTWJ",
  },
  pages: [
    {
      title: "Calendar",
      icon: "calendar",
      colour: "secondary",
      route: "/calendar",
    },
    {
      title: "A-Z of Waste",
      icon: "a-z",
      colour: "secondary",
      route: "/a-z",
    },
    {
      title: "Bin Info",
      icon: "bin",
      colour: "secondary",
      route: "/bin-info",
    },
    {
      title: "Submit a Waste Request",
      icon: "waste-request",
      colour: "secondary",
      route: "/forms",
    },
    {
      title: "Recycling Near Me",
      icon: "recycling-near-me",
      colour: "secondary",
      route: "/info-multi-map/1",
    },
    {
      title: "Waste Facilities",
      icon: "facilities",
      colour: "secondary",
      route: "/info/2",
      params: { title: "Waste Facilities" },
    },
    {
      title: "Ask Anthony",
      icon: "ask-anthony",
      colour: "secondary",
      route: "/forms/ask-anthony",
    },
    {
      title: "Waste Vouchers",
      icon: "trailer-dropoff",
      colour: "secondary",
      route: "/info-details/4",
    },
    // {
    //   title: "Vouchers",
    //   icon: "voucher",
    //   colour: "secondary",
    //   route: "/vouchers",
    // },
    {
      title: "More Info",
      icon: "information-circle",
      colour: "secondary",
      route: "/more-info",
    },
    {
      title: "News & Alerts",
      icon: "news",
      colour: "secondary",
      route: "/news-and-alerts",
    },
  ],
};
